@tailwind base;
@tailwind components;
@tailwind utilities;

/* html {
	background: #121322;
} */

nav ul li .active {
	opacity: 1;
}
nav ul li:last-of-type {
    margin-right: 0;
}

@layer utilities {
    @variants responsive {
        .masonry {
            column-gap: 1.5em;
            column-count: 1;
        }
        .masonry-sm {
            column-gap: 1.5em;
            column-count: 2;
        }
        .masonry-md {
            column-gap: 1.5em;
            column-count: 3;
        }
        .break-inside {
            break-inside: avoid;
        }
    }
}

.masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .masonry-col {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .masonry-grid .masonry-col > a > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }


  .loading {
    position:relative;
    display:block;
    border-radius:2px;
    width:4em;
    height:4em;
    /* background:rgba(255,255,255,0.2); */
    animation:flip 1s linear infinite;
  }
  
  @keyframes flip{
    50%{
      transform:perspective(100px) rotateX(180deg) rotateY(0deg);
    }
    100%{
      transform:perspective(100px) rotateX(180deg) rotateY(180deg);    
    }
  }
  

  .lettered-avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }